import { createHashHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import installProjectInfo from '@forchange/info'

import App from './app'
import apis from '../library/apis'

installProjectInfo()

window.apis = apis
window.router = createHashHistory()

ReactDOM.render(<App />, document.getElementById('app'))
