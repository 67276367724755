import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import { Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const { Option } = Select

import Avatar from '../Avatar/Avatar'
import { isAryVal, debounce } from '../../modules/home/util'
import { to } from '@/library/utils'

import './search.less'
interface IProps {
  width: string | number
  suffixIcon?: boolean // 是否展示选择框后缀 icon，默认 true
  clearSearchList?: boolean
  clearSearch: boolean
  handleSearch: (userInfo: IUser) => void
}

const SearchName = (props: IProps) => {
  const [defaultActiveFirstOption, setDefaultActiveFirstOption] = useState<boolean>(false)
  const [userId, setUserId] = useState<any>({})
  const [userList, setUserList] = useState<IUser[]>([])
  const [searchName, setSearchName] = useState<any>(undefined)
  useEffect(() => {
    // 获取登录人
    const userInfo: any = localStorage.getItem('user_info')
    userInfo && setUserId(JSON.parse(userInfo).id)
  }, [])
  useEffect(() => {
    // 重置搜索
    setSearchName(undefined)
  }, [props.clearSearchList, props.clearSearch])
  const getUser = async (name: string) => {
    const [err, res] = await to(
      window.apis.getUserList({
        params: {
          name,
          user_id: userId
        }
      })
    )
    if (!res.errcode) {
      if (res.data && Array.isArray(res.data)) {
        setUserList(res.data)
        setDefaultActiveFirstOption(res.data.length === 1)
      } else {
        setUserList([])
      }
    }
  }
  const handleChangeName = (value: any, option: any) => {
    props.handleSearch(JSON.parse(value))
    setSearchName(option.props.children[1])
    setUserList([])
  }
  const handleSearch = debounce(
    (name: string) => {
      if (name.length) {
        setSearchName(name)
        getUser(name)
      } else {
        setSearchName(undefined)
        setUserList([])
      }
    },
    500,
    false
  )
  const onMouseLeave = () => {
    setDefaultActiveFirstOption(false)
  }

  const suffixIconVisible =
    typeof props.suffixIcon === 'boolean' ? props.suffixIcon : true
  return (
    <div styleName="search">
      <Select
        getPopupContainer={(triggerNode: any) => triggerNode.parentElement}
        defaultActiveFirstOption={defaultActiveFirstOption}
        showSearch
        style={{ width: props.width }}
        showArrow={false}
        filterOption={false}
        value={searchName}
        onChange={handleChangeName}
        onSearch={handleSearch}
        onFocus={() => setUserList([])}
        notFoundContent={'无匹配结果'}
        onMouseLeave={onMouseLeave}
        placeholder="搜索员工"
      >
        {isAryVal(userList) &&
          searchName?.length > 0 &&
          userList.map((user: IUser) => (
            <Option key={user.id} value={JSON.stringify(user)}>
              <Avatar avatar={user.avatar} name={user.name} />
              {user.name}
            </Option>
          ))}
      </Select>
      {suffixIconVisible && (
        <span className="placeholder_icon">
          <SearchOutlined />
        </span>
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  clearSearch: state.okr.clearSearch
})
export default connect(mapStateToProps)(SearchName)
