import React from 'react'
import { HashRouter, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'
import CommonLoad from './../components/commonLoad/loading'
import Header from '@/components/Header/header'
import SideMenu from '@/components/Menu/Menu'
import Evaluate from '@components/evaluate/evaluate'
import addScreenResize from './screen-resize'

let routes: any[] = []

try {
  const context = require.context(`../modules`, true, /.*\/routes\.tsx?$/)
  context.keys().forEach((key: string) => {
    const route = context(key).default
    routes = routes.concat(route)
  })
} catch (err) {
  console.warn(err.message)
}

routes.push({
  path: '/',
  redirect: '/okr'
})

console.table(routes)

class SwitchRouterComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
  }

  componentDidUpdate(prevProps: any): void {
    if (this.props.location !== prevProps.location) {
      console.log('>>> Router Change: ', this.props.location)
    }
  }

  render(): JSX.Element {
    return (
      <>
        <React.Suspense fallback={<CommonLoad text="加载中" />}>
          {this.props.location.pathname !== '/login' && (
            <>
              <Header />
              <SideMenu />
            </>
          )}
          <Switch>
            {routes.map((route, index) =>
              route.redirect ? (
                <Redirect exact key={index} from={route.path} to={route.redirect} />
              ) : (
                <Route
                  key={index}
                  path={route.path}
                  exact={false}
                  component={route.component}
                />
              )
            )}
          </Switch>
        </React.Suspense>
        <Evaluate route={window.location.hash.split('/')[1]} />
      </>
    )
  }
}

const WithRouterComponent = withRouter(SwitchRouterComponent)
const WithScreenResizeComponent = addScreenResize(WithRouterComponent)

/* eslint-disable */
class RouterComponent extends React.Component<any, any> {
  render(): JSX.Element {
    return (
      <HashRouter>
        <WithScreenResizeComponent />
      </HashRouter>
    )
  }
}

export default hot(RouterComponent)
